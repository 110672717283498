$primary100: #9FB6E1;
$primary400: #9FB6E1;
$primary500: #154F9A;
$primary900: #154F9A;

$smallPadding: 4px;
$medPadding: 16px;
$footerHeight: 25px;

$fontAwesomeColorPrimary: $primary400;
$fontAwesomeColorSecondary: $primary500;


$headerColorPrimary: $primary500;
$headerColorSecondary: $primary400;

$transitionSpeed: 600ms;

@mixin debug {
  border-width: 1px;
  border-color: rgb(random(255), random(255), random(255));
  border-style: solid;
}

@mixin noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

// global
:root {
  font-family: 'Roboto Mono';
  color: $primary900
}

body {
  margin: 0px 0px 0px 0px;
}

h1 {
  font-size: xx-large;
  margin: 0px;
  color: $headerColorPrimary
}

h2 {
  color: $headerColorPrimary;
  font-size: x-large;
}

a {
  color: $primary500;
}

// header
.header-container {
  margin: $smallPadding;
  display: flex;
  align-content: center;
}

.header-left-container {
  display: flex;
}

.header-image-container {
  flex: 1;
  max-width: 512px;
}

.header-image {
  max-width: 100%;
  border-radius: 5%;
}

.header-title-container {
  flex: 5;
  padding-left: $medPadding;
  align-self: flex-end;
}


// main
.main-container {
  display: flex;
  height: 100%;
}

.main-right-container {
  flex: 1
}

// nav
.nav-container {
  display: flex;
  flex: 1;
  height: 80vh;
  max-height: 1024px;
  max-width: 200px;
}

.content-container {
  flex: 3;
  padding-left: $medPadding;
  margin-bottom: $footerHeight;

}

.inner-nav-container {
  z-index: 10;
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.inner-nav-style {
  z-index: 0;
  display: flex;
  bottom: 0px;
  left: 0px;
  height: 57%;
  border-left: $primary500;
  border-left-width: 1px;
  margin-left: $medPadding;
  border-left-style: solid;
}

.inner-nav-cross-bar {
  z-index: 0;
  width: 300px;
  border-bottom: $primary500;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  margin-left: -300px;
}

.nav-item {
  cursor: pointer;
  display: flex;
  flex: 1;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.nav-item:hover {
  font-size: large;
  transition: $transitionSpeed;
}

.empty-nav-item {
  transition: flex $transitionSpeed;
  width: 100%;
}

.nav-link:hover {
  filter: grayscale(0%) opacity(1);
  background: $primary900;
  color: $primary400
}

.link-text {
  color: $primary500;
  @include noselect();
}

.nav-icon {
  padding: $smallPadding;
  justify-self: flex-end;
  color: $primary500;
}

.contact-options-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.contact-option {
  padding: $medPadding;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.resume-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: flex-end;
  padding: $medPadding;
  height: 50%;
  text-decoration: none;
}

.mobile-resume-button {
  display: none;
}

.contact-option-icon {
  margin: $smallPadding;
  color: $primary500;
}

.footer {
  position: fixed;
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  bottom: 0px;
  width: 100%;
  color: $primary100;
  background-color: $primary500;
  height: $footerHeight;
}

@media only screen and (max-width: 600px) {

  .nav-container {
    width: 100%;
    max-width: 100%;
  }

  .inner-nav-container {
    flex-direction: row;
  }

  .main-container {
    flex-direction: column-reverse;
  }

  .nav-link {
    justify-content: center;
  }

  .empty-nav-item {
    display: none;
  }

  .link-text {
    display: none;
  }

  .nav-item {
    padding: $medPadding;
    margin: 0px;
    border: $primary500;
    border-width: 1px;
    border-style: solid;
  }

  .active-nav-item {
    background-color: $primary100;
  }

  .content-container {
    overflow: scroll;
    min-height: 60vh;
    max-height: 60vh;
    margin-bottom: 0;
  }

  .inner-nav-style {
    display: none;

  }

  .inner-nav-cross-bar {
    display: none;
  }

  .header-container {
    background-color: $primary100;
  }

  body {
    margin: 0px;
  }

  .header-image-container {
    padding: $smallPadding;
    align-self: center;
  }

  .header-title-container {
    padding-left: $smallPadding;
  }

  .footer {
    font-size: x-small;
    position: relative;
    height: auto;
    // position: absolute;
    // bottom: 0px;
    // @include debug();
  }

  .mobile-resume-button {
    display: flex;
    align-self: center;
    padding: $smallPadding;
    margin: $smallPadding;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 50%;
    text-decoration: none;
  }

  .resume-button {
    display: none;
    align-self: center;
    padding: $smallPadding;
    margin: $smallPadding;
  }
}